import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { DataTransferInfo } from '../models/services/dataTransferInfo';
import { Report } from '../models/services/insights/insights';
import { Audience, AudienceFile } from '../models/services/audience';
import { TableDataPreview } from '../models/services/core/table-data-preview';
import { TableData } from '../models/services/core/table-data';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private httpService: HttpService
  ) { }

  public getDataTransfers(): Observable<DataTransferInfo> {
    let url: string = `${environment.adminServicesApiBaseUri}/dataTransfers`;
    return this.httpService.get(url);
  }  

  public downloadDataTransfersWAMInfoCsv(): Observable<Blob> {
    let url: string = `${environment.adminServicesApiBaseUri}/dataTransfers/wam/csv`;
    return this.httpService.getFile(url);
  }  

  public downloadDataTransfersPublicisInfoCsv(): Observable<Blob> {
    let url: string = `${environment.adminServicesApiBaseUri}/dataTransfers/publicis/csv`;
    return this.httpService.getFile(url);
  }  

  public getDataTransfersReportDetail(): Observable<Report> {
    let url: string = `${environment.adminServicesApiBaseUri}/dataTransfers/report`;
    return this.httpService.get(url);
  }   

  public refreshAudience(audienceId: number, companyId: number): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/refresh`;
    return this.httpService.postNoResponse(url, null, companyId);
  }

  public removeAudience(audienceId: number, companyId: number): Observable<Audience[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}`;
    return this.httpService.delete(url, companyId);
  }

  public removeAudienceFile(audienceId: number, audienceFileId: number, companyId: number): Observable<AudienceFile[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/file/${audienceFileId}`;
    return this.httpService.delete(url, companyId);
  }

  public resendAudienceFile(audienceId: number, audienceFileId: number, companyId: number): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/file/${audienceFileId}/send`;
    return this.httpService.postNoResponse(url, null, companyId);
  }

  public getSqlQueryDataPreview(query: string): Observable<TableDataPreview> {
    let url: string = `${environment.adminServicesApiBaseUri}/sql-runner/data-preview`;
    return this.httpService.post(url, { 'sql': query });
  }  

  public getSqlQueryData(query: string): Observable<TableData> {
    let url: string = `${environment.adminServicesApiBaseUri}/sql-runner/data`;
    return this.httpService.post(url, { 'sql': query });
  }    
}
